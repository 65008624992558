<template>
  <div>
    <v-card
      flat
    >
      <v-toolbar dense dark color="primary">
        <v-toolbar-title><h4 class="font-weight-light">LOAN TRANSMITTAL</h4>
        </v-toolbar-title>
      </v-toolbar>
      <v-card-text>
        <v-form ref="form" class="multi-col-validation mt-6">
          <v-row>
            <v-col
              md="12"
              cols="12"
              v-if="items!=null"
            >
              <v-text-field
                v-model="transmital_no"
                label="Transmital #"
                dense
                outlined
                readonly
              ></v-text-field>
            </v-col>
            <v-col
              md="12"
              cols="12"
              v-if="items!=null"
            >
              <v-text-field
                v-model="transmital_date"
                label="Date of Transmittal"
                dense
                outlined
                readonly
              ></v-text-field>
            </v-col>
            <v-col
              md="12"
              cols="12"
              v-if="items===null"
            >
              <v-text-field
                v-model="transmital_date"
                label="Date of Transmittal"
                dense
                outlined
                :rules="rules.default_max_45_character_and_no_empty_rule"
                type="date"
              ></v-text-field>
            </v-col>
            <v-col cols="12" v-show="alert">
              <v-alert
                color="warning"
                text
                class="mb-0"

              >
                <div class="d-flex align-start">
                  <v-icon color="warning">
                    {{ icons.mdiAlertOutline }}
                  </v-icon>

                  <div class="ms-3">
                    <p class="text-base font-weight-medium mb-1">
                      {{alert_message}}
                    </p>
                  </div>
                </div>
              </v-alert>
            </v-col>

            <v-col cols="12">
              <v-btn
                color="primary"
                class="me-3 mt-4"
                @click="generate_transmital"
                v-if="!saving &&items===null"
              >
                Generate Transmital
              </v-btn>
              <v-progress-circular
                :size=50
                :width="5"
                color="primary"
                indeterminate
                v-if="saving"
              ></v-progress-circular>
            </v-col>
          </v-row>
        </v-form>
        <v-layout row wrap class="align-center mx-2 mt-5">
          <v-layout row wrap>
            <v-flex xs12 md6>
              <v-toolbar dense dark color="primary">
                <v-toolbar-title><h4 class="font-weight-light">LOANS APPROVED</h4>
                </v-toolbar-title>
              </v-toolbar>
              <v-layout row wrap class="mx-2 mt-2">
                <v-flex xs12 md12>
                  <v-data-table dense
                                :headers="headers"
                                height="600"
                                :items="search_items"
                                disable-pagination
                                hide-default-footer
                  >
                    <template v-slot:item="{ item }">
                      <tr
                        @click="get_search_items_info(item)"
                        :class="{'info white--text': item.id===selectedDepositId}"
                      >
                        <td>
                          {{ item.type_of_loan
                          }}
                        </td>
                        <td>
                          {{ item.specify_type_of_loan
                          }}
                        </td>
                        <td>
                          {{ item.members.last_name+', '+item.members.first_name }}
                        </td>
                        <td class="text-center">
                          {{ formatPrice(item.amount) }}
                        </td>
                      </tr>
                    </template>
                  </v-data-table>
                </v-flex>
              </v-layout>
            </v-flex>
            <v-flex xs12 md6>
              <v-toolbar dense dark color="info">
                <v-toolbar-title><h4 class="font-weight-light">SELECTED LOANS</h4>
                </v-toolbar-title>
              </v-toolbar>
              <v-col cols="12">
                <v-btn
                  color="primary"
                  class="me-3 mt-4"
                  @click="done_transmital"
                  v-if="!saving &&items!=null"
                >
                  Done Transmital
                </v-btn>
                <v-progress-circular
                  :size=50
                  :width="5"
                  color="primary"
                  indeterminate
                  v-if="saving"
                ></v-progress-circular>
              </v-col>
              <v-layout row wrap class="mx-2 mt-2">
                <v-flex xs12 md12>
                  <v-data-table dense
                                :headers="headers"
                                :items="search_items2"
                                disable-pagination
                                hide-default-footer
                  >
                    <template v-slot:item="{ item }">
                      <tr
                      >
                        <td>
                          {{ item.type_of_loan
                          }}
                        </td>
                        <td>
                          {{ item.specify_type_of_loan
                          }}
                        </td>
                        <td>
                          {{ item.members.last_name+', '+item.members.first_name }}
                        </td>
                        <td class="text-center">
                          {{ formatPrice(item.amount) }}
                        </td>
                      </tr>
                    </template>
                  </v-data-table>
                </v-flex>
              </v-layout>
            </v-flex>
          </v-layout>
        </v-layout>
      </v-card-text>
    </v-card>
    <snack-bar-dialog
      :snackbar_flag="this.snackbar"
      :color="this.snackbar_color"
      :snackbar_text="this.snackbar_text"
    />
  </div>
</template>

<script>
  import {
    mdiAlertOutline, mdiCloudUploadOutline
    , mdiPlus
    , mdiAccountSearchOutline
  } from '@mdi/js'
  import {mapGetters, mapActions} from "vuex";
  import snackBarDialog from '@/components/dialogs/notifications_dialog/Snackbar'

  export default {
    components: {
      snackBarDialog,
    },
    setup() {
      return {
        icons: {
          mdiAlertOutline,
          mdiCloudUploadOutline,
          mdiPlus,
          mdiAccountSearchOutline,
        },
      }
    },
    data() {
      return {

        saving: false,
        alert: false,
        alert_message: '',

        transmital_date: '',
        transmital_no: '',
        selectedDepositId: -1,
        headers: [
          {text: 'TYPE OF LOAN', value: 'name', sortable: false},
          {text: 'SPECIFY', value: 'sequence', sortable: false},
          {text: 'NAME', value: 'sequence', sortable: false},
          {text: 'AMOUNT', value: 'sequence', sortable: false},
        ],
        items: {},
        search_items: [],
        search_items2: [],
      }
    },
    mounted() {
      this.initialize_data()
    },
    computed: {
      ...mapGetters('form_rules', ['rules']),
      ...mapGetters('system_data', ['snackbar', 'snackbar_color', 'snackbar_text']),
      ...mapGetters('authentication', ['branch_id']),
    },
    methods: {
      ...mapActions('system_data', ['change_snackbar']),
      ...mapActions('loans_data', ['list_of_loans_for_transmital', 'generate_loan_transmital', 'done_generate_loan_transmital']),
      formatPrice(value) {
        let val = (value / 1).toFixed(2).replace(',', '.')
        return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
      },
      initialize_data() {
        this.saving = false
        this.alert = false
        const data = new FormData()
        data.append('branch_id', this.branch_id);
        data.append('month_of', this.month_of);
        this.list_of_loans_for_transmital(data)
          .then(response => {
            this.items = response.data[0].transmital
            this.search_items = response.data[0].loans

            if (this.items != null) {
              this.transmital_no = this.items.id
              this.transmital_date = this.items.date
            }
          })
          .catch(error => {
            console.log(error)
          })
      },
      itemExists(value) {
        return this.search_items2.includes(value)
      },
      get_search_items_info(item) {
        this.selectedDepositId = item.id
        if (!this.itemExists(item)) {
          this.search_items2.push(item)
        } else {
          this.change_snackbar({
            show: true,
            color: 'error',
            text: 'ALREADY SELECTED',
          })
        }
      },
      generate_transmital() {
        this.saving = true
        this.alert = false
        if (this.$refs.form.validate()) {
          const data = new FormData()
          data.append('branch_id', this.branch_id);
          data.append('date', this.transmital_date);
          this.generate_loan_transmital(data)
            .then(response => {
              var color = 'success'
              if (response.status === 201) {
                color = 'error'
              }
              this.change_snackbar({
                show: true,
                color: color,
                text: response.data,
              })
              this.initialize_data()
            })
            .catch(error => {
              this.alert = true
              this.alert_message = error
              this.saving = false
            })
        } else {
          this.alert = true
          this.alert_message = 'Please Fill up the field/s'
          this.saving = false
        }
      },
      done_transmital() {
        this.saving = true
        this.alert = false
        if (this.$refs.form.validate()) {
          const data = new FormData()
          data.append('transmital_no', this.transmital_no);
          data.append('details', JSON.stringify(this.search_items2));
          this.done_generate_loan_transmital(data)
            .then(response => {
              var color = 'success'
              if (response.status === 201) {
                color = 'error'
              }
              this.change_snackbar({
                show: true,
                color: color,
                text: response.data,
              })
              this.initialize_data()
            })
            .catch(error => {
              this.alert = true
              this.alert_message = error
              this.saving = false
            })
        } else {
          this.alert = true
          this.alert_message = 'Please Fill up the field/s'
          this.saving = false
        }
      }
    }
  }
</script>
